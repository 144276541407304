<template>
    <!-- 账户余额顶部组件 -->
    <div class="center_wallet_top_box">
        <!-- 标题 -->
        <div class="center_wallet_top_title">账户余额</div>
        <!-- 内容框 -->
        <div class="center_wallet_top_content clearfix">
            <!-- 余额 -->
            <div class="center_wallet_top_balance">
                <span>账户余额：</span>
                <span class="center_wallet_top_balance_detail">{{basic_data.balance}}</span>
                <span>元</span>
            </div>
            <!-- 按钮 -->
            <!-- <div class="center_wallet_top_btn_box">
                <el-button type="danger">充值</el-button>
                <el-button type="info">提现</el-button>
                <el-button type="info">转账</el-button>
            </div> -->
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    computed: mapState({
        basic_data: state => state.user.basic_data
    }),
}
</script>

<style scoped>
/* 顶部组件盒子 */
.center_wallet_top_box{
    margin-bottom: 20px;
}
/* 标题 */
.center_wallet_top_title{
    margin-bottom: 15px;
    font-size: 18px;
}
/* 内容框 */
.center_wallet_top_content{
    padding: 20px;
    background: #E9FFFF
}
/* 余额 */
.center_wallet_top_balance{
    float: left;
    line-height: 40px;
}
.center_wallet_top_balance>span{
    font-size: 16px;
}
.center_wallet_top_balance>.center_wallet_top_balance_detail{
    font-size: 19px;
    color: #F56C6C;
}
/* 按钮盒子 */
.center_wallet_top_btn_box{
    float: right;
}
</style>
